import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Avue from '@smallwei/avue/lib/avue-mobile.min.js'
import '@smallwei/avue/lib/index.css'
// import VueWechatTitle from 'vue-wechat-title'
Vue.use(require('vue-wechat-title'))

// 导入全局css样式
import './assets/css/global.css'

// 配置axios
import axios from 'axios'

import Meta from 'vue-meta'

// 自适应配置js文件
import './JS/plugin'

// websocket
import socketApi from './JS/socket'

// 引入自定义loading组件
import Loading from './components/loading'
Vue.prototype.$loading = Loading

Vue.prototype.socketApi = socketApi

// 引入样式
import 'vue-easytable/libs/theme-default/index.css'
// 引入组件库
import VueEasytable from 'vue-easytable'

// 工具类函数
import utils from './JS/utils'

import './plugins/vant.js'

Vue.use(Avue)

Vue.use(utils)

Vue.use(VueEasytable)

Vue.use(Meta)

Vue.prototype.$http = axios

// 配置请求根路径
axios.defaults.baseURL = ''

// 请求响应拦截
axios.interceptors.request.use(config => {
  console.log(router)
  const tenantId = router.app.$options.router.history.current.query.tid
  const token = router.app.$options.router.history.current.query.token
  const cid = router.app.$options.router.history.current.query.cid

  if (token != undefined) {
    config.headers.Authorization = config.headers.Authorization ? config.headers.Authorization : 'Bearer ' + token
    config.headers['TENANT-ID'] = tenantId
  } else {
    config.headers['TENANT-ID'] = 1
  }
  if (config.method === "get") {
    config.data = { unused: 0 }
  }
  config.headers['Content-Type'] = 'application/json;charset=utf-8'
  // }
  return config
}, err => {
  console.log(err)
})
// 响应拦截
axios.interceptors.response.use(res => {
  return res
}, err => {
  console.log(err.response)
  return Promise.reject(err.response)
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.query !== {}) {
    localStorage.setItem('tenantId', to.query.tid)
    localStorage.setItem('token', to.query.token)
    localStorage.setItem('cid', to.query.cid)
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
