import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'queue'
  },
  // jyq
  {
    path: '/bicyclo',
    name: 'bicyclo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bicyclo/bicyclo.vue'),
    meta: {
      title: '在线下单'
    }
  },
  // end
  {
    path: '/place_order',
    name: 'PlaceOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaceOrder.vue'),
    meta: {
      title: '在线下单'
    }
  },
  {
    path: '/order_for_jhyb',
    name: 'PlaceOrderPro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaceOrderPro.vue'),
    meta: {
      title: '在线下单'
    }
  },
  {
    path: '/order_for_yjh',
    name: 'PlaceOrderPro2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlaceOrderPro2.vue'),
    meta: {
      title: '在线下单'
    }
  },
  {
    path: '/order_for_sh',
    name: 'PlaceOrderSh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sh/PlaceOrderSh.vue'),
    meta: {
      title: '在线下单'
    }
  },
  {
    path: '/order_for_lb',
    name: 'LbPlaceOrderPro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Lbpop/LbPlaceOrderPro.vue'),
    meta: {
      title: '在线下单',
      keepAlive: true
    }
  },
  {
    path: '/car_list',
    name: 'CarList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Lbpop/CarList.vue'),
    meta: {
      title: '选择车辆'
    }
  },
  {
    path: '/orderer_info',
    name: 'OrderInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlukOrder/OrdererInfo.vue'),
    meta: {
      title: '订货人信息',
      keepAlive: true
    }
  },
  {
    path: '/bluk_order',
    name: 'BlukOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlukOrder/BlukOrder.vue'),
    meta: {
      title: '批量下单',
      keepAlive: true
    }
  },
  {
    path: '/pay',
    name: 'pay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pay.vue'),
    meta: {
      title: '支付'
    }
  },
  {
    path: '/driver_orders',
    name: 'DriverOrders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DriverOrders.vue'),
    meta: {
      title: '接单'
    }
  },
  {
    path: '/wechat_msg_detail',
    name: 'WechatMsgDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WechatMsgDetail.vue'),
    meta: {
      title: '生产统计分析通知'
    }
  },
  {
    path: '/procurement_schedule',
    name: 'ProcurementSchedule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/ProcurementSchedule.vue'),
    meta: {
      title: '采购进厂明细表'
    }
  },
  {
    path: '/purchase_summary',
    name: 'PurchaseSummary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/PurchaseSummary.vue'),
    meta: {
      title: '采购进厂汇总表'
    }
  },
  {
    path: '/product_sales_schedule',
    name: 'ProductSalesSchedule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/ProductSalesSchedule.vue'),
    meta: {
      title: '产品销售明细表'
    }
  },
  {
    path: '/product_sales_summary',
    name: 'ProductSalesSummary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/ProductSalesSummary.vue'),
    meta: {
      title: '产品销售汇总表'
    }
  },
  {
    path: '/customer_report',
    name: 'CustomerReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/CustomerReport.vue'),
    meta: {
      title: '客户往来明细表'
    }
  },
  {
    path: '/report',
    name: 'Report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/Report.vue'),
    meta: {
      title: '报表'
    }
  },
  // 采购进厂明细表查询
  {
    path: '/dssearch',
    name: 'DsSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/DsSearch.vue'),
    meta: {
      title: '查询'
    }
  },
  // 采购进厂汇总表查询
  {
    path: '/sssearch',
    name: 'SsSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/SsSearch.vue'),
    meta: {
      title: '查询'
    }
  },
  // 销售进厂明细表查询
  {
    path: '/pssearch',
    name: 'PsSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/PsSearch.vue'),
    meta: {
      title: '查询'
    }
  },
  // 销售进厂汇总表查询
  {
    path: '/psssearch',
    name: 'PssSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/PssSearch.vue'),
    meta: {
      title: '查询'
    }
  },
  // 客户往来汇总表查询
  {
    path: '/cdsearch',
    name: 'CdSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/report/CdSearch.vue'),
    meta: {
      title: '查询'
    }
  },
  {
    path: '/qrInfo',
    name: 'qrInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/qrInfo.vue'),
    meta: {
      title: '订单信息'
    }
  },
  {
    path: '/selfCar',
    name: 'selfCar',
    component: () => import(/* webpackChunkName: "about" */ '../views/znfl/selfCar.vue'),
    meta: {
      title: '自动装车'
    }
  },
  {
    path: '/selfCarSp',
    name: 'selfCarSp',
    component: () => import(/* webpackChunkName: "about" */ '../views/znfl/selfCarSp.vue'),
    meta: {
      title: '手工装车'
    }
  },
  {
    path: '/selfCarApp',
    name: 'selfCarApp',
    component: () => import(/* webpackChunkName: "about" */ '../views/znfl/selfCarApp.vue'),
    meta: {
      title: '车辆自助'
    }
  },
  {
    path: '/transitPage',
    name: 'transitPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/znfl/transitPage.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/traceInfo',
    name: 'traceInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/trace/traceInfo.vue'),
    meta: {
      title: '追溯码信息'
    }
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/trace/service.vue'),
    meta: {
      title: '售后'
    }
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/demo.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/queue',
    name: 'queue',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/queue/normal.vue'),
    meta: {
      title: '排队信息'
    }
  },
  {
    path: '/detailsInfo',
    name: 'detailsInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detailsInfo.vue'),
    meta: {
      title: '详情信息'
    }
  }
]

const router = new VueRouter({
  routes
})

// // 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   console.log(to)
//   console.log(from)
//   // if (to.path === '/') {
//   //   router.push({ path: '/place-order?id=92'})
//   // } else if (to.path === '/place-order') {
//   //   router.push({ path: '/place-order?id=92' })
//   // } else if (to.path === '/place-order-pro') {
//   //   router.push({ path: '/place-order-pro?id=92' })
//   // }
//   if (to.meta.content) {
//     console.log(12)
//     let head = document.getElementsByTagName('head')
//     let meta = document.createElement('meta')
//     console.log(head)
//     console.log(meta.name)
//     meta.content = to.meta.content
//     meta.name = 'viewport'
//     head[0].appendChild(meta)
//   } else {
//     console.log(12)
//     let head = document.getElementsByTagName('head')
//     let meta = document.getElementsByTagName('meta')
//     console.log(head)
//     console.log(meta)
//   }
//   next()
// })

export default router
