import Vue from 'vue'
import { Button, Field, Form, Collapse, CollapseItem, Popup, Picker, DatetimePicker, Search, Cell, CellGroup, Switch, List, Dialog, Toast, RadioGroup, Radio, Stepper, Icon, Checkbox, CheckboxGroup, Divider, Empty, Tag, Loading, Overlay, Step, Steps, Tab, Tabs, ImagePreview, Image as VanImage } from 'vant'

Vue.use(Button)
Vue.use(Field)
Vue.use(Form)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Search)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Switch)
Vue.use(List)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Stepper)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Divider)
Vue.use(Empty)
Vue.use(Tag)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(ImagePreview)
Vue.use(VanImage)