<template>
  <div id="app">
    <router-view v-if="!$route.meta.keepAlive"/>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'app',
  metaInfo() {
      return {
        meta: this.metaData
    }
  },

  data () {
    return {
      metaData: [{
        name: 'viewport',
        content: 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
      }]
    }
  },

  watch: {
    $route(to, from) {
      console.log(to)
        if (to.name == 'Report') {
          this.metaData = [{
            name: 'viewport',
            content: 'user-scalable=yes, initial-scale=0.5, maximum-scale=3.0, minimum-scale=0'
          }]
        } else if (to.name == 'CustomerReport') {
          this.metaData = [{
            name: 'viewport',
            content: 'user-scalable=yes, initial-scale=0.5, maximum-scale=3.0, minimum-scale=0'
          }]
        } else {
          this.metaData = [{
            name: 'viewport',
            content: 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'
          }]
        }
      },
    },
}
</script>
<style lang='less' scoped>

</style>
